<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <slot />
      </v-col>

      <event-card
        v-for="(event, i) in events"
        :key="event.id"
        :size="layout[i]"
        :value="event"
      />
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'EventFeed',

    components: {
      EventCard: () => import('@/components/base/EventCard'),
    },

    data: () => ({
      layout: [2, 2, 1, 2, 2, 3, 3, 3, 1, 2, 2],
      page: 1,
    }),

    computed: {
      ...mapState(['events']),
    },

    watch: {
      page () {
        window.scrollTo(0, 0)
      },
    },
  }
</script>
